import { BuildingOffice2Icon, CalendarIcon, InformationCircleIcon, MapPinIcon } from '@heroicons/react/24/outline'
import { json, type LinksFunction, type LoaderFunctionArgs, type MetaFunction } from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'
import classnames from 'classnames'
import { Fragment } from 'react'
import logos from 'app/images/logos-homepage'
import { Button } from '~/components/design-system/button'
import Card from '~/components/landing-pages/card'
import { PADDING } from '~/components/landing-pages/constants'
import Header from '~/components/landing-pages/header'
import SearchBanner from '~/components/landing-pages/search-banner'
import TextBanner from '~/components/landing-pages/text-banner'
import UsersReport from '~/components/landing-pages/users-report'
import { SCHEDULE_BUILDER_FE_URL } from '~/constants/env-variables.server'
import StatsSchema from '~/schemas/stats-schema'
import { getUserProfile } from '~/services/auth.server'
import { getRoutescannerApiConfig, RoutescannerApi } from '~/services/routescanner-api.server'
import marqueeCss from '~/styles/marquee.css?url'
import * as images from './images'
import type { SitemapFunction } from 'remix-sitemap'

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: marqueeCss }]
}

export const meta: MetaFunction = () => [
  {
    title: 'Routescanner – Find Better Routes and Optimize Container Logistics',
    description:
      'Optimize your supply chain with multimodal transport solutions and emission reporting. Discover efficient container routes and benchmark your logistics performance.',
    keywords:
      'end-to-end container logistics, route optimization, emission reporting, multimodal transport solutions, supply chain efficiency, sustainable logistics, transport benchmarking'
  }
]

export const sitemap: SitemapFunction = () => ({
  exclude: true
})

export async function loader({ request }: LoaderFunctionArgs) {
  const profile = await getUserProfile(request, { allowUnauthenticated: true })
  const config = await getRoutescannerApiConfig(request)
  const API = new RoutescannerApi(config)

  try {
    const metricsResponse = await API.fetchMetrics()
    const metricsResult = StatsSchema.parse(metricsResponse.data)

    return json({
      profile: profile?.profile || null,
      scheduleBuilderUrl: SCHEDULE_BUILDER_FE_URL,
      metrics: metricsResult
    })
  } catch (error) {
    return json({
      profile: profile?.profile || null,
      scheduleBuilderUrl: SCHEDULE_BUILDER_FE_URL,
      metrics: {
        schedulesCount: 0,
        terminalsCount: 0,
        locodesCount: 0,
        operatorsCount: 0
      }
    })
  }
}

export default function HomePage() {
  const { metrics } = useLoaderData<typeof loader>()

  return (
    <Fragment>
      <Header image={images.headerImage} carouselImages={logos}>
        <Fragment>
          <span className="text-brand-utility">
            End-to-end <br className="md:hidden 2xl:block" /> container routes&nbsp;
          </span>
          <span>for optimization and emission reporting</span>
        </Fragment>
      </Header>

      <TextBanner>
        By combining&nbsp;
        <span className="text-brand-utility">sea</span>,&nbsp;
        <span className="text-brand-utility">rail</span>,&nbsp;
        <span className="text-brand-utility">barge</span>, and&nbsp;
        <span className="text-brand-utility">trucking container schedules</span> in one place, we offer&nbsp;
        <br className="hidden 2xl:block" />
        <span className="text-brand-utility">end-to-end solutions</span> to optimize your container logistics
      </TextBanner>

      <section
        className={classnames(
          'relative flex flex-col gap-y-2 bg-white',
          'md:grid md:grid-cols-3 md:grid-rows-[max-content,auto] md:gap-x-3',
          'lg:gap-x-6',
          PADDING,
          'md:px-6'
        )}>
        <Card
          image={
            <div className="relative">
              <img src={images.caseOne} alt="Case 1" className="w-full rounded-t object-cover" />
              <img src={images.caseOneLogo} alt="Case 1" className="absolute left-2 top-2 size-12 object-cover" />
            </div>
          }
          subtitle="Find new routes"
          title={`"We found new operators and container shipping options"`}
          readMoreLink="/news/rabelink-logistics-explores-new-container-shipping-routes-with-routescanner">
          <div className="flex flex-row items-center gap-x-2.5">
            <img src={images.jeroenVerheij} alt="Jeroen Verheij" className="size-12 rounded-full border border-brand-light object-cover" />
            <div>
              <div className={classnames('text-sm font-bold', 'lg:text-base')}>Jeroen Verheij</div>
              <div className={classnames('text-xs', 'lg:text-sm')}>Manager Air & Ocean at Rabelink Logistics</div>
            </div>
          </div>
        </Card>

        <Card
          image={<img src={images.caseTwo} alt="Case 2" className="w-full rounded-t border border-brand-light object-cover" />}
          subtitle="Optimize networks"
          title="Benchmark routes on lead time and emissions"
          readMoreLink="/features/route-optimizer">
          <p className={classnames('text-sm', 'lg:text-lg')}>
            See at a glance which routes can have shorter lead times or lower emissions with Route Optimizer.
          </p>
        </Card>

        <Card
          image={<img src={images.caseThree} alt="Case 3" className="w-full rounded-t object-cover" />}
          subtitle="Emission reporting"
          title="Calculate the emissions of your container transport"
          readMoreLink="/features/emissions-calculation">
          <p className={classnames('text-sm', 'lg:text-lg')}>
            Upload your shipment data, or connect to our API, and receive carbon footprint reports or CO2e values.
          </p>
        </Card>
      </section>

      <section
        className={classnames(
          'relative flex flex-col items-center gap-y-6',
          'md:grid md:grid-cols-[1fr,1fr] md:gap-x-10',
          'xl:grid-cols-[2fr,3fr] xl:gap-x-20',
          PADDING
        )}>
        <img src={images.featuresBackground} className="absolute inset-0 -z-10 w-full object-cover" />

        <div className="flex flex-col gap-y-2">
          <div className="flex flex-row gap-x-1">
            <div className="rounded border border-callout-green-30 bg-callout-green-10 px-1 py-px text-sm/5 font-bold uppercase text-callout-green-90">
              Optimizer
            </div>
          </div>

          <div className={classnames('text-xl/tight font-black', 'lg:text-[32px]/tight')}>
            <span className="text-brand-utility">Find better routes</span> from anywhere to anywhere
          </div>

          <p className={classnames('text-base text-brand-dark', 'lg:text-lg')}>
            Choose the optimal route of your containers by searching schedules anywhere in the world. We show you the best route, based on your
            preferences, from loading dock to final destination.
          </p>

          <Button variant="primary" size="lg" className="mt-4" asChild>
            <Link to="/app/optimizer">Start searching</Link>
          </Button>
        </div>

        <div className="rounded border border-brand-light bg-white p-2 pb-0 shadow-strong">
          <img
            src={images.scheduleCoverage}
            alt="Schedule coverage"
            loading="lazy"
            className="max-h-[200px] w-full rounded object-cover xl:max-h-[286px]"
          />

          <div className={classnames('-mx-2 grid grid-cols-2 text-base', 'xl:text-lg', 'xl:grid-cols-[repeat(4,minmax(max-content,1fr))]')}>
            <div className={classnames('flex flex-col gap-y-1 border-b border-r border-brand-light p-3', 'xl:border-b-0 xl:p-6')}>
              <div className="font-bold text-brand-utility">{metrics.locodesCount}</div>
              <div className="font-bold">
                Ports & <span className="hidden 2xl:inline">Inland</span> hubs
              </div>
              <MapPinIcon className="size-6 text-brand-dark" />
            </div>

            <div className={classnames('flex flex-col gap-y-1 border-b border-brand-light p-3', 'xl:border-b-0 xl:border-r xl:p-6')}>
              <div className="font-bold text-brand-utility">{metrics.schedulesCount}</div>
              <div className="font-bold">Schedules</div>
              <CalendarIcon className="size-6 text-brand-dark" />
            </div>

            <div className={classnames('flex flex-col gap-y-1 border-r border-brand-light p-3', 'xl:p-6')}>
              <div className="font-bold text-brand-utility">{metrics.terminalsCount}</div>
              <div className="font-bold">Terminals</div>
              <svg width="24" height="24" viewBox="0 0 25 25" fill="none" strokeWidth="1.5" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path
                    d="M9.3335 21.2109H20.3335H14.0835H7.8335M7.8335 21.2109V9.21094M7.8335 21.2109H4.8335M7.8335 9.21094H3.12671C2.25354 9.21094 1.79977 8.17034 2.39391 7.53049L6.87585 2.70379C7.15257 2.40579 7.58219 2.30504 7.96254 2.44895L20.7188 7.27565C21.7585 7.66905 21.4765 9.21094 20.3649 9.21094H15.3335M7.8335 9.21094H15.3335M15.3335 9.21094V13.2109M15.3335 13.2109H12.3335C11.7812 13.2109 11.3335 13.6587 11.3335 14.2109V16.2109C11.3335 16.7632 11.7812 17.2109 12.3335 17.2109H19.3335C19.8858 17.2109 20.3335 16.7632 20.3335 16.2109V14.2109C20.3335 13.6587 19.8858 13.2109 19.3335 13.2109H15.3335Z"
                    stroke="#4E6161"
                  />
                </g>
              </svg>
            </div>

            <div className={classnames('flex flex-col gap-y-1 p-3', 'xl:p-6')}>
              <div className="font-bold text-brand-utility">{metrics.operatorsCount}</div>
              <div className="font-bold">Operators</div>
              <BuildingOffice2Icon className="size-6 text-brand-dark" />
            </div>
          </div>
        </div>
      </section>

      <section
        className={classnames(
          'flex flex-col items-center gap-y-6',
          'md:grid md:grid-cols-[1fr,1fr] md:gap-x-10',
          'xl:grid-cols-[2fr,3fr] xl:gap-x-20',
          PADDING
        )}>
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-row gap-x-1">
            <div className="rounded border border-callout-green-30 bg-callout-green-10 px-1 py-px text-sm/5 font-bold uppercase text-callout-green-90">
              Strategy suite
            </div>

            <div className="rounded border border-callout-blue-30 bg-callout-blue-10 px-1 py-px text-sm/5 font-bold uppercase text-callout-blue-90">
              Paid plan
            </div>
          </div>

          <div className={classnames('text-xl/tight font-black', 'lg:text-[32px]/tight')}>
            <span className="text-brand-utility">Know where to improve</span> by benchmarking yourself against the market
          </div>

          <p className={classnames('text-base text-brand-dark', 'lg:text-lg')}>
            It can be hard to determine which steps you should and can take when improving your container logistics. For each route Routescanner
            provides a benchmark that compares your performance to alternative routes available in the market.
          </p>

          <Button variant="primary" size="lg" className="mt-4" asChild>
            <Link to="/contact">Get in touch</Link>
          </Button>
        </div>

        <div className="rounded border border-brand-light bg-white p-2 shadow-strong">
          <img src={images.strategySuite} alt="Strategy suite" loading="lazy" className="w-full rounded object-cover" />
        </div>
      </section>

      <section
        className={classnames(
          'flex flex-col items-center gap-y-6',
          'md:grid md:grid-cols-[1fr,1fr] md:gap-x-10',
          'xl:grid-cols-[2fr,3fr] xl:gap-x-20',
          PADDING
        )}>
        <div className="flex flex-col gap-y-2">
          <div className={classnames('text-xl/tight font-black', 'lg:text-[32px]/tight')}>
            <span className="text-brand-utility">Save time and costs</span> by finding schedule information for all your route options
          </div>

          <p className={classnames('text-base text-brand-dark', 'lg:text-lg')}>
            Forget about checking multiple websites of different operators, trying to find what their schedules look like. With Routescanner, all
            information can be found in one overview.
          </p>

          <Button variant="primary" size="lg" className="mt-4" asChild>
            <Link to="/app/optimizer">Get started</Link>
          </Button>
        </div>

        <div className="rounded border border-brand-light bg-white p-2 shadow-strong">
          <img src={images.routeDetails} alt="Route details" loading="lazy" className="w-full rounded object-cover" />
        </div>
      </section>

      <section
        className={classnames(
          'relative grid grid-cols-1 items-center gap-x-6 overflow-hidden bg-brand-light-bg py-10',
          'lg:grid-cols-[3fr,2fr] lg:gap-x-10',
          '2xl:lg:rounded-lg 2xl:gap-x-20 2xl:py-20',
          PADDING
        )}>
        <img src={images.emissionReportingBackground} className="absolute inset-0 size-full object-cover" />

        <div className="z-10 flex flex-col gap-y-2.5">
          <div className="flex flex-row gap-x-1">
            <div className="rounded border border-callout-green-30 bg-white px-1 py-px text-sm/5 font-bold uppercase text-callout-green-90">
              CSRD Compliance
            </div>
            <div className="rounded border border-callout-green-30 bg-white px-1 py-px text-sm/5 font-bold uppercase text-callout-green-90">
              GLEC v3.1
            </div>
          </div>

          <div className={classnames('text-2xl/tight font-black', 'xl:text-[32px]/tight')}>
            <span className="text-brand-utility">Comply with</span> required emission reporting
          </div>

          <div className={classnames('flex flex-row items-center gap-x-2 text-sm font-bold', 'xl:text-base')}>
            <InformationCircleIcon className="size-6" />
            We are the only provider of accurate distances of barge & rail routes
          </div>

          <p className={classnames('text-base', 'xl:text-lg')}>
            If you do business in the EU or the USA it is likely you have to start reporting on your transport emissions, sooner or later. We are
            unique in providing you with the distances needed to accurately calculate emissions for rail & barge journeys.
          </p>

          <Button variant="primary" size="lg" className="mt-4" asChild>
            <Link to="/features/emissions-calculation">Learn more</Link>
          </Button>
        </div>

        <img src={images.emissionReporting} alt="Emission reporting" loading="lazy" className="z-10 hidden h-full w-full object-contain lg:block" />
      </section>

      <section
        className={classnames(
          'relative z-20 flex flex-col gap-y-2 bg-white',
          'md:grid md:grid-cols-3 md:grid-rows-[max-content,auto] md:gap-x-3',
          'lg:gap-x-6',
          PADDING,
          'md:px-6'
        )}>
        <Card
          image={<img src={images.shippingCargoOwners} alt="Shippers & cargo owners" loading="lazy" className="h-full rounded-t object-cover" />}
          subtitle="Shippers & Cargo Owners"
          title="Find better ways to ship your cargo"
          readMoreLink="/industries/shippers-and-cargo-owners">
          <p className={classnames('text-sm', 'lg:text-lg')}>
            Report your emission, rethink your supply chain and reduce inefficiencies through continuous supply chain optimization.
          </p>
        </Card>

        <Card
          image={<img src={images.freightForwarders} alt="Freight forwarders" loading="lazy" className="h-full rounded-t object-cover" />}
          subtitle="Freight Forwarders"
          title="Attract and retain more business by showcasing your routes"
          readMoreLink="/industries/freight-forwarders">
          <p className={classnames('text-sm', 'lg:text-lg')}>
            Appear as the best option for cargo owners and freight forwarders searching for door-to-door container transport solutions.
          </p>
        </Card>

        <Card
          image={<img src={images.carriersOperators} alt="Carriers & operators" loading="lazy" className="h-full rounded-t object-cover" />}
          subtitle="Carriers & Operators"
          title="Share your schedules and grow your business"
          readMoreLink="/industries/carriers-and-operators">
          <p className={classnames('text-sm', 'lg:text-lg')}>
            Be found within our schedule network and gain new customers to grow your container business.
          </p>
        </Card>
      </section>

      <UsersReport />

      <SearchBanner />
    </Fragment>
  )
}
